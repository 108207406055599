import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import Categories from './components/Categories';
import ProductList from './components/ProductList';
import Pagination from './components/Pagination';
import Footer from './components/Footer';
import ProductDetail from './components/ProductDetail';  // Импортируем компонент для карточки товара
import About from './components/About';  // Импортируем компонент About
import Contact from './components/Contact';  // Импортируем компонент Contact
import './App.css';

const App = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);
  const [totalProducts, setTotalProducts] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    loadProducts(currentPage, selectedCategory);
  }, [currentPage, selectedCategory]);

  const loadProducts = (page, category) => {
    setLoading(true);
    let apiUrl = `https://api.dvmehanika.ru/products?page=${page}&per_page=${productsPerPage}`;
    if (category && category !== 'Все товары') {
      apiUrl += `&category=${encodeURIComponent(category)}`;
    }

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
        setTotalProducts(data.total);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
        setLoading(false);
      });
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Router>
      <div className="app">
        <Header />
        <div className="app-content">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Banner />
                  <Categories onSelectCategory={handleCategorySelect} />
                  {loading ? (
                    <div>Загрузка...</div>
                  ) : (
                    <>
                      <ProductList products={products} />
                      <Pagination
                        productsPerPage={productsPerPage}
                        totalProducts={totalProducts}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </>
                  )}
                </>
              }
            />
            {/* Маршрут для страницы товаров */}
            <Route path="/products/:id" element={<ProductDetail products={products} />} />
            {/* Маршруты для страниц About и Contact */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;