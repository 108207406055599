import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaCog, FaSlidersH, FaFilter, FaCogs, FaWrench, FaBolt, FaIndustry, FaBox, FaOilCan } from 'react-icons/fa';
import './Categories.css';

// Маппинг английских категорий на русские названия для отображения
const categoryMapping = {
  'All Products': 'Все товары',  // Добавили категорию "Все товары"
  'Regulators': 'Регуляторы',
  'Controllers': 'Контроллеры',
  'Digital Regulators': 'Цифровые регуляторы',
  'Components': 'Комплектующие',
  'Filter Elements': 'Фильтрующие элементы',
  'Automation': 'Автоматика',
  'Hyundai Products': 'Hyundai товары',
  'Inverters': 'Инверторы',
  'Electrical and Cable Products': 'Электрика и кабельная продукция',
  'Oils': 'Масла'
};

const categories = [
  { name: 'All Products', icon: <FaBars /> },  // Добавляем категорию "Все товары"
  { name: 'Regulators', icon: <FaSlidersH /> },
  { name: 'Controllers', icon: <FaCog /> },
  { name: 'Digital Regulators', icon: <FaFilter /> },
  { name: 'Components', icon: <FaCogs /> },
  { name: 'Filter Elements', icon: <FaWrench /> },
  { name: 'Automation', icon: <FaBolt /> },
  { name: 'Hyundai Products', icon: <FaIndustry /> },
  { name: 'Inverters', icon: <FaCog /> },
  { name: 'Electrical and Cable Products', icon: <FaBox /> },
  { name: 'Oils', icon: <FaOilCan /> }
];

const Categories = ({ onSelectCategory }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCategoryClick = (categoryName) => {
    onSelectCategory(categoryName === 'All Products' ? '' : categoryName); // Для категории "Все товары" сбрасываем фильтр
    setIsMenuOpen(false);  // Закрываем меню после выбора категории
  };

  return (
    <div className="categories">
      <h2 className="categories__title">Категории</h2>
      <button className="categories__burger" onClick={toggleMenu}>
        <FaBars />
      </button>
      <div className={`categories__list ${isMenuOpen ? 'categories__list--open' : ''}`}>
        {categories.map((category, index) => (
          <div 
            key={index} 
            className="category" 
            onClick={() => handleCategoryClick(category.name)}
          >
            <div className="category__icon">{category.icon}</div>
            <div className="category__name">{categoryMapping[category.name]}</div> {/* Выводим название на русском */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;