// src/components/About.js
import React from 'react';
import { FaCog, FaBolt, FaFan, FaPumpSoap, FaThermometerHalf, FaCogs, FaTabletAlt, FaPlug, FaChartBar, FaTools, FaWrench } from 'react-icons/fa';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>О компании</h1>
      <p className="about-intro">Общество с ограниченной ответственностью «ДВ МЕХАНИКА» занимается поставками и монтажом промышленного оборудования, такого как:</p>
      <ul className="about-list">
        <li><FaWrench className="about-icon" /> Дизельгенераторные установки</li>
        <li><FaCog className="about-icon" /> Электродвигатели</li>
        <li><FaPumpSoap className="about-icon" /> Насосы</li>
        <li><FaBolt className="about-icon" /> Частотные преобразователи</li>
        <li><FaFan className="about-icon" /> Вентиляционные системы</li>
        <li><FaThermometerHalf className="about-icon" /> Теплотехника</li>
        <li><FaCogs className="about-icon" /> Редукторы</li>
        <li><FaTabletAlt className="about-icon" /> Сенсорные панели</li>
        <li><FaPlug className="about-icon" /> Силовая автоматика</li>
        <li><FaChartBar className="about-icon" /> АСУ ТП</li>
        <li><FaTools className="about-icon" /> Электрощитовое оборудование</li>
      </ul>
      <p className="about-conclusion">Общество с ограниченной ответственностью «ДВ МЕХАНИКА» работает с 2020 года и за короткий период стало надежным партнером для предприятий на всей территории России.</p>
    </div>
  );
};

export default About;