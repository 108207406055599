import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './ProductCard.css';

const ProductCard = ({ product }) => {
  // Добавляем базовый путь к изображению, если оно относительное
  const imageUrl = product.image_url 
    ? (product.image_url.startsWith('http') 
        ? product.image_url 
        : `https://api.dvmehanika.ru/static/images/${product.image_url}`) // Используем image_url вместо img_url
    : null;

  return (
    <div className="product-card">
      <Link to={`/products/${product.id}`} className="product-card__link">
        <div className="product-card__image">
          <LazyLoad height={150} offset={10}>
            {imageUrl ? (
              <img src={imageUrl} alt={product.title} />
            ) : (
              <div className="placeholder-icon">⚙️</div>  // Иконка если нет изображения
            )}
          </LazyLoad>
        </div>
        <div className="product-card__title">{product.title || 'Без названия'}</div>
        <div className="product-card__price">
          {product.price ? `Цена: ${product.price}` : 'Цена: По запросу'}
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;