// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header__top">
        <div className="header__logo">
          <Link to="/">ДВ Механика</Link>
        </div>
        <div className="header__actions">
          <a href="mailto:sale@dvmehanika.ru" className="header__contact">Связаться с нами</a>
        </div>
      </div>
      <nav className="header__nav">
        <ul className="header__nav-list">
          <li className="header__nav-item"><Link to="/">Главная</Link></li>
          <li className="header__nav-item"><Link to="/about">О компании</Link></li>
          <li className="header__nav-item"><Link to="/contact">Контакты</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;