// src/components/Contact.js
import React from 'react';
import { FaBuilding, FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h1>Контакты</h1>
      <div className="contact-info">
        <p><FaBuilding className="contact-icon" /> ООО "ДВ МЕХАНИКА"</p>
        <p><FaBuilding className="contact-icon" /> ИНН: 4101192842</p>
        <p><FaBuilding className="contact-icon" /> ОГРН: 1204100002556</p>
        <p><FaMapMarkerAlt className="contact-icon" /> Юридический адрес: 683002, Камчатский край, Петропавловск-Камчатский г, Тундровая ул, дом 4А, помещение 1</p>
        <p><FaMapMarkerAlt className="contact-icon" /> Почтовый адрес: 683042, Камчатский край, Петропавловск-Камчатский г, а/я 1</p>
        
        <div className="contact-section">
          <h2>Петропавловск-Камчатский</h2>
          <p><FaPhone className="contact-icon" /> Телефон: 8-914-020-77-99</p>
          <p><FaEnvelope className="contact-icon" /> Email: sale@dvmehanika.ru</p>
        </div>
        
        <div className="contact-section">
          <h2>Владивосток</h2>
          <p><FaPhone className="contact-icon" /> Телефон: 8-914-705-43-71</p>
          <p><FaEnvelope className="contact-icon" /> Email: VladSale@dvmehanika.ru</p>
        </div>
      </div>
      
      <div className="contact-map">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Axxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx&source=constructor"
          width="100%"
          height="400"
          frameBorder="0"
          title="Карта"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;