// src/components/Banner.js
import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <img className="banner__image" src="https://avatars.mds.yandex.net/i?id=445b9013bf203e62f0942ce90ea712d1_l-7761242-images-thumbs&n=13" alt="ДВ МЕХАНИКА" />
      <div className="banner__overlay">
        <h1 className="banner__title">Добро пожаловать в ДВ МЕХАНИКА</h1>
        <p className="banner__subtitle">Лучшие товары для вашего бизнеса</p>
      </div>
    </div>
  );
};

export default Banner;