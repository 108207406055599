// src/components/ProductDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetail.css';

const ProductDetail = ({ products }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const foundProduct = products.find(p => p.id === parseInt(id));
    setProduct(foundProduct);

    console.log("ID из URL:", id);
    console.log("Найденный продукт:", foundProduct);
  }, [id, products]);

  if (!product) {
    return <div className="product-detail__not-found">Продукт не найден</div>;
  }

  // Убедимся, что путь к картинке корректный
  const imageUrl = product.image_url
    ? (product.image_url.startsWith('http')
        ? product.image_url
        : `https://api.dvmehanika.ru/static/images/${product.image_url}`)
    : null;

  return (
    <div className="product-detail-container">
      <div className="product-detail-image">
        {imageUrl ? (
          <img src={imageUrl} alt={product.title} />
        ) : (
          <div className="product-detail-placeholder">⚙️</div> // Большая иконка шестеренки
        )}
      </div>
      <div className="product-detail-info">
        <h2 className="product-detail-title">{product.title || 'Без названия'}</h2>
        <div className="product-detail-description-container">
          <p className="product-detail-description">{product.description || 'Описание отсутствует'}</p>
        </div>
        <a href="mailto:sale@dvmehanika.ru" className="product-detail-get-price">
          Запросить цену
        </a>
      </div>
    </div>
  );
};

export default ProductDetail;